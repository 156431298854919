/* On screens that are 600px wide or less, the input field width will be decrease */

@media only screen and (min-width: 600px) and (max-width: 860px) {
  .cards-list-wrap .card-item-wrap {
    width: 48% !important;
  }
}

@media screen and (max-width: 600px) {
  .forgot-main {
    min-width: 20rem !important;
    padding: 10px !important;
  }

  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding-left: 0px !important;
    padding-right: 0px;
  }

  .template-list-main .list-section {
    max-height: 64vh;
  }

  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
    margin-left: 0px !important;
  }

  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    font-family: "Univers LT Std" !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  .overlay-content .header {
    display: block;
  }

  .width20rem {
    max-width: 20rem !important;
  }

  .mindDialog {
    padding: 2rem 0;
  }

  .profileStack.topStack {
    margin-bottom: 0px;
    gap: 10px !important;
  }

  .topStack.trophyTopStack button {
    margin-bottom: 0px;
  }

  .header-text span {
    display: block;
    white-space: normal;
  }

  .template-list-main {
    margin-top: 30px;
  }

  .cartWrap {
    flex-direction: column;
  }

  .cartWrap .template-list-main {
    width: 100%;
  }

  .days-section .slots {
    width: 144px !important;
    height: 45px;
    font-size: 12px;
  }

  .css-14t77gz-MuiDialogContent-root {
    padding: 0;
  }

  .css-14t77gz-MuiDialogContent-root::-webkit-scrollbar {
    display: none;
  }

  .explore-filter-date-picker-close {
    padding: 4px;
    background-color: #e7eff9;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    margin: 6px 10px 0 0;
    cursor: pointer;
  }

  .link-line.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways {
    font-size: 10px;
    font-family: 'Univers LT Std';
  }

  .custom-date-select .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 14px;
  }

  .custom-date-select .MuiInputBase-root {
    height: 44px;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10.5px 10px;
  }

  .class-footer-link .MuiBox-root .MuiSvgIcon-root {
    width: 0.65em !important;
    height: 0.65em !important;
  }

  .class-footer-link .MuiBox-root img {
    width: 0.8em !important;
    height: 0.8em !important;
  }

  .class-footer-link .MuiBox-root .MuiTypography-root span,
  .link-line.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways span {
    font-size: 17px !important;
  }

  .profile-main {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .bundleBox>div {
    width: 100%;
    margin-top: 15px;
    height: auto;
  }

  .bundleBox {
    flex-direction: column;
  }

  .signup-main .signup-form {
    width: 20rem !important;
  }

  .signup-main .signup-tabs-main .signup-tabs {
    width: 20rem !important;
  }

  .appointment-container.MuiContainer-root.MuiContainer-maxWidthLg {
    margin: 0;
    padding: 10px;
    overflow: auto;
    height: 100vh !important;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none;
  }

  .sidebar-footer-horizontal-associated,
  .sidebar-footer-horizontal {
    left: 39%;
  }

  .top-header.shop-header {
    padding: 10px 4px;
    height: 44px;
    margin-bottom: 10px;
  }

  .second-section .main-schedule-box,
  .booking-notes {
    padding: 17px;
  }

  .all_schedule_layout {
    height: 67vh !important;
    overflow: auto !important;
  }

  .global-modal-main-success .MuiPaper-root.MuiPaper-elevation {
    border-radius: 20px;
    width: 100% !important;
  }

  .earnedFlex {
    margin: 0px 0 50px;
    justify-content: space-around;
    gap: 0;
  }

  .top-header .header-btn {
    font-weight: 700;
    padding: 7px 23px !important;
  }

  .dashboard-mid-section .schedule-section {
    min-height: 139px;
    height: 170px;
  }

  .dashboard-container .achievements-section,
  .top-achieved-list,
  .dashboard-container .featured-section,
  .holders-item,
  .slider-section .content-section,
  .locationSidebar .main-schedule-box,
  .template-list-main,
  .filter-slider-wrap .slider-wrap {
    padding: 17px;
    margin-top: 20px;
  }

  .slider-section .image-section {
    width: 129px;
    height: 129px;
    padding: 7px;
  }

  .slider-section .content-section {
    width: auto;
    width: 57%;
    align-items: center;
    gap: 10px;
    padding: 17px;
  }

  .book-btn,
  .book-btn-help-support {
    padding: 11px 19px !important;
  }

  .fontsize-22px {
    font-size: 22px !important;
  }

  .schedule-section-wrap .schedule-section-box {
    width: 275px;
    height: 180px;
  }

  .schedule-section-action .action-btn {
    font-size: 12px;
    width: 220px !important;
  }

  .cartWrap .template-list-main .item .MuiBox-root:nth-of-type(1) p:nth-of-type(1),
  .codeBox p {
    font-size: 14px;
  }

  .cartWrap .template-list-main .item .MuiBox-root:nth-of-type(1) p:nth-of-type(2),
  .MuiTypography-body1 {
    font-size: 14px !important;
    font-family: "Univers LT Std" !important;
  }

  .checkout_shop_form {
    width: auto;
    padding: 17px;
    background-color: #ffff;
    border-radius: 15px;
    margin-top: 15px;
  }

  .dialog-main .MuiPaper-root {
    padding: 0px;
  }

  .schedule-section-action p {
    font-size: 12px !important;
  }

  .dialog-main .dialog-actions {
    gap: 10%;
  }

  .schedule-section-wrap .schedule-section-box {
    padding-top: 70px;
  }

  .css-ypiqx9-MuiDialogContent-root {
    /* padding: 0px 24px 10px 24px !important; */
  }

  .schedule-section-wrap {
    gap: 10px;
  }

  .arrow-btn {
    height: 30px !important;
    min-width: 30px !important;
  }

  .field-section .appointment-dropdown {
    width: 100% !important;
  }

  .appointment-main .field-section {
    flex-direction: column;
    margin-top: 0px !important;
  }

  .appointment-dropdown .MuiFormLabel-root.MuiInputLabel-root {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .appointment-main {
    margin-top: 30px;
    width: 100%;
  }

  .week-days-box .slots {
    min-width: 45px !important;
  }

  .days-section .week-days-box {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
  }

  .filter-section .days-section,
  .filter-dropdown-section {
    flex-direction: column;
  }

  .days-section .slots {
    width: 100% !important;
  }

  .cards-list-wrap {
    flex-direction: column;
  }

  .cards-list-wrap .card-item-wrap {
    width: 100%;
  }

  .slots-display .slots {
    width: 100% !important;
  }

  .confirm-booking-main {
    flex-direction: column;
    gap: 20px;
  }

  .overlay-content {
    padding: 20px;
  }

  /* .overlay-content .header .img-footer {
    align-items: flex-end;
  } */

  .appointment-container .appointment-header {
    margin-bottom: 0px;
  }

  .credit-remain {
    width: 100%;
    margin: 0px !important;
  }

  .width-styling {
    width: 100% !important;
  }

  .available-credit-list .credit-box {
    width: 100% !important;
    margin: 0px;
  }

  .buy-credit-main .available-credit-list {
    margin-top: 20px;
    padding: 15px;
    gap: 10px;
    font-size: 12px !important;
  }

  .credit-header p {
    font-size: 14px !important;
  }

  .purchaseFlex {
    flex-direction: column;
  }

  .purchaseFlex>div {
    width: 100% !important;
  }

  .dialog-actions p {
    font-size: 22px !important;
  }

  .all_schedule_wrapper .css-6ydhqn-MuiGrid-root {
    max-width: 98% !important;
  }

  .booking-notes.service-wrap {
    gap: 15px !important;
  }

  .mt-minus-18 {
    margin-top: -43px !important;
  }

  .MuiDateCalendar-root {
    width: 350px !important;
  }

  .cartWrap .template-list-main .item {
    padding: 9px 11px;
    margin-top: 12px;
  }

  .topStack.trophyTopStack button {
    min-width: auto !important;
    font-size: small;
  }

  .history-box {
    flex-direction: column;
  }

  .history-box .display-booking-wrap,
  .progresswrapp img {
    width: 100% !important;
  }

  .card-input.search img {
    padding: 0px !important;
  }

  .css-1a2i2bp-MuiFormControl-root-MuiTextField-root {
    margin-top: 0 !important;
  }

  .appointment-container.MuiContainer-root.MuiContainer-maxWidthLg::-webkit-scrollbar {
    display: none !important;
  }

  .question-card {
    padding: 12px;
  }

  .css-14vs3bf-MuiTypography-root {
    font-size: 1rem !important;
  }

  .locationHead>p:nth-of-type(1) {
    padding: 13px 12px !important;
  }

  .global-modal-main .MuiDialogContent-root,
  .modal-content-wrap .terms-box {
    padding: 12px;
  }

  .splash-btn button {
    font-size: 16px;
    font-weight: 600;
  }

  .signup-form .form-fullwidth-field {
    flex-direction: column;
    gap: 12px !important;
  }

  .form-fullwidth-field .field-section label {
    margin-bottom: 0px !important;
  }

  .form-fullwidth-field .field-section {
    width: 100% !important;
  }

  .signup-form label {
    margin-bottom: -10px;
    margin-top: 10px;
  }

  .create-account-btn>button {
    margin-top: 15px;
  }

  .signup_location_popup .MuiDialog-container .MuiPaper-root {
    width: 100%;
    border-radius: 25px;
  }

  .booking-wrap {
    padding: 30px;
  }
}